import React, { useEffect, useRef, useState } from 'react';
import classes from './FullWidthSection.module.scss';
import Box from '../../../../../remitbee/components/box/Box';
import Typography from '../../../../../remitbee/components/typography/Typography';
import theme from '../../../../../remitbee/theme/Theme';
import { useMediaQuery } from '@mui/material';
import Markdown from 'markdown-to-jsx';
import Spacing from '../../../../../remitbee/styles/spacing/Spacing';
import CopyIcon from '../../../../../../public/icons/other/copyBlue.svg';
import CopiedIcon from '../../../../../../public/icons/other/copiedBlue.svg'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Divider from '../../../../../remitbee/components/divider/Divider';

const CardList = ({ cardType, cardBackground, cardContent, cardBorder, cardPadding, cardAlign='left' }) => {
  const [maxHeight, setMaxHeight] = useState(0);
  const isNotMobile = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
  const boxShadowDefaultStyle = '0px 1px 2px 0px rgba(16, 24, 40, 0.05)';
  const cardRefs = useRef<any>([]);
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleCopy = () => {
    setOpenTooltip(true);

    const timeout = setTimeout(() => {
      setOpenTooltip(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }

  useEffect(() => {
    const heights = cardRefs?.current?.map((card) => card?.getBoundingClientRect()?.height);
    const maxCardHeight = Math.max(...heights);
    setMaxHeight(maxCardHeight);
  }, [cardRefs?.current?.length]);

  return (
    <Box className={cardAlign === 'center' ? classes['rb-cardList-center-container'] : classes['rb-cardList-container']}  mt={isNotMobile ? 12 : 10} gap={cardPadding === '0px' ? '32px':'16px'} >
      {cardContent.map((e, i) => (
        <Box
          key={i}
          componentRef={(el) => (cardRefs.current[i] = el)}
          style={{ background: cardBackground ? cardBackground : '#FFF', padding: cardPadding ? cardPadding : '24px', minHeight: e?.cardHeight || (isNotMobile && maxHeight ) , border: cardBorder, boxShadow: cardBorder ? 'none' : boxShadowDefaultStyle, width: isNotMobile && e.cardWidth}}
          className={classes['rb-card-container']}
        >
          {(cardType === 'icon' || cardType === 'number') && (
            <Box>
              {cardType === 'icon' && (
                <div className={`${classes['rb-icon-container']} ${e?.imageAlign && classes[`rb-icon-align-${e.imageAlign}`]}`}>
                  <img
                    src={e?.icon}
                    alt="image"
                    width={e?.iconWidth || 32}
                    height={e?.iconHeight || 32}
                  />
                </div>
              )}
              {cardType === 'number' && (
                <div className={classes['rb-numbered-icon']}>
                  <Typography variant="textXl" weight="bold" color="#FFF">
                    {i + 1}
                  </Typography>
                </div>
              )}
            </Box>
          )}
         <div className={isNotMobile && (e?.buttonText || e?.promoCode) && classes['rb-card-box']}>
        {cardType!== "promoCode" && <Box
            display="flex"
            flexDirection="column"
            gap={8}
            mt={cardType === 'icon' || cardType === 'number' ? 8 : 0}
          >
            <Typography variant="textXl" align={e?.titleAlign ? e?.titleAlign : 'left'} weight="bold" color={theme.palette.primary.navy}>
            <Markdown
              children={e?.title}
            />
            </Typography>
           {e?.text && <Typography paragraph={true} variant="textMd" align={e?.textAlign ? e?.textAlign : 'left'} weight="regular">
            <Markdown
              children={e?.text}
            />
            </Typography>}
            {e?.timeText &&<Typography paragraph={true} variant="textMd" align={e?.textAlign ? e?.textAlign : 'left'} weight="regular">
            <Markdown
              children={e?.timeText}
            />
            </Typography>}

            {e?.hoursText && <Typography paragraph={true} variant="textMd" align={e?.textAlign ? e?.textAlign : 'left'} weight="regular">
            <Markdown
              children={e?.hoursText}
            />
            </Typography>}
            {e?.locationText &&<Typography paragraph={true} variant="textMd" align={e?.textAlign ? e?.textAlign : 'left'} weight="regular">
            <Markdown
              children={e?.locationText}
            />
            </Typography>}
          </Box>}
          {
         cardType=== "promoCode" &&   <div className = {classes['rb-promo-code-box']}>

<Typography variant="textXl" align={e?.titleAlign ? e?.titleAlign : isNotMobile ? 'left' : 'center'} weight="bold" color={theme.palette.primary.navy}>
            <Markdown
              children={e?.title}
            />
            </Typography>
            {!isNotMobile && <> <Spacing variant='betweenButtons' />
            <Divider />
            <Spacing variant='betweenButtons' />
            </>}
           {e?.text && <Typography paragraph={true} variant="textMd" align={e?.textAlign ? e?.textAlign : isNotMobile ? 'left' : 'center'} weight="regular">
           {typeof e?.text === 'string' ?  <Markdown
              children={e?.text}
            /> : e?.text}
            </Typography>}
              </div>
          }
          {!isNotMobile && e?.promoCode && <Spacing variant='titleToCard' />}
          {
            cardType=== "promoCode" && <div className={classes['rb-claim-code-container']}>
            <input type="text" value={e?.promoCode} readOnly className={classes['rb-claim-code-input']} />
            <CopyToClipboard text={e?.promoCode} onCopy={handleCopy}>
              {openTooltip ?
                <img src={CopiedIcon} alt='copied'/>
                : <img src={CopyIcon} alt='copy'/>}
            </CopyToClipboard>
          </div>
          }
          
          {!isNotMobile && e?.buttonText && <Spacing variant='titleToCard' />}
          {e?.buttonText && <Box display='flex' justifyContent={isNotMobile &&'right'}>
          <Typography paragraph={true} variant="textMd" align={e?.textAlign ? e?.textAlign : 'left'} weight="medium">
            <Markdown
              children={e?.buttonText}
            />
            </Typography>
            </Box>}
          </div>
          {cardType === 'specialTag' && (
            <div className={classes['rb-className-specialTag']}>
              <Typography variant="textSm" weight="medium" color={theme.palette.primary.blue}>
                {e?.tag}
              </Typography>
            </div>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default CardList;
