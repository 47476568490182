import react,{useState, useEffect} from 'react';
import Typography from '../../../../../remitbee/components/typography/Typography';
import Box  from '../../../../../remitbee/components/box/Box';
import classes from './FullWidthSection.module.scss';
import theme from '../../../../../remitbee/theme/Theme';
import { InputBase } from '@mui/material';
import NumberFormat from '../HalfWidthSection/NumberFormat';
import Divider from '../../../../../remitbee/components/divider/Divider';
import Flag from '../../../../../remitbee/components/flag/Flag';
import Spacing from '../../../../../remitbee/styles/spacing/Spacing';
import { CustomSlider } from '../../../../../components/landingPage/components/FeesCalculator/CustomSlider';
import Button from '../../../../../remitbee/components/button/Button';
import { useRouter } from 'next/router';
import { useMediaQuery } from '@mui/material';

let marks = [500, 1000, 1500, 2000, 2500].map(e => ({ value: e }));

const FeesCalculator = () => {
    const isNotMobile = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`);
    const router = useRouter();
    const [resultAmount, setResultAmount] = useState(null);
    const [sliderValue, setSliderValue] = useState(500);
    const [fees, setFees] = useState({
        wallet: 0,
        interac: 2.99,
        debit: 2.99
      });

    const handleSliderChange = (event, newValue) => {
        setSliderValue(newValue);
      };

    const handleInputChange = event => {
        const value = event.target.value.includes(',') ? event.target.value.replace(',', '') : event.target.value
        if (Number(value) > 10000) {
          setResultAmount(value)
          setSliderValue(10)
        }
        else {
          setResultAmount(value)
          setSliderValue(value === 0 ? 0 : Number(value));
        }
      };

      const calculateFees = () => {
        let amount = Number(sliderValue);
        if (amount !== resultAmount) setResultAmount(amount);
    
        let newFees = {
        wallet: amount >= 499.99 ? 0 : 2.99,
        interac: 2.99,
          debit: (amount <= 100 ? 2.99 : amount <= 300 ? 4.99 : amount <= 500 ? 6.99 : amount <= 1000 ? 8.99 : amount <= 1500 ? 14.99 : amount <= 2000 ? 19.99 : amount <= 2500 ? 24.99 : 29.99)
        };
        setFees(newFees);
      };
    
      useEffect(() => {
        calculateFees();
      }, [sliderValue]);

    return (
        <Box className={classes['rb-fees-calculator-root']}>
            <Box display={isNotMobile && 'flex'} gap={48} alignItems='center'>
                <Typography variant='textXl' weight='medium' color={theme.palette.input.heading} align={!isNotMobile && 'center'}>
                    {"You are sending:"}
                </Typography>
                {!isNotMobile && <Spacing variant='betweenSectionToInputFields' />}
                <div className={classes['rb-fees-calculator-button-box']}>
                <div className={classes['rb-fees-calculator-input']}>
                    <InputBase
                  id='amount'
                  aria-label='Amount'
                  placeholder={'0.00'}
                  inputComponent={NumberFormat}
                  inputProps={{ decimalScale: 2, thousandSeparator: true }}
                  value={resultAmount}
                  classes={{ input: classes.fixedValue }}
                  onChange={handleInputChange}></InputBase>
                  <div className={classes['rb-fees-calculator-flag']}>
                  <Flag code='CA' width={36} height={24} />
                  <Typography variant='textXl' weight='medium' color={theme.palette.input.heading}>{"CAD"}</Typography>
                  </div>
                    </div>
                   {isNotMobile && <Button className={classes['rb-fees-calculator-button']} id='start-sending' variant='filledPrimary' onClick={() => router.push("/signup")}>{"Start sending"}</Button>}
                </div>
            </Box>
            <Spacing variant='betweenSectionToInputFields' />
            <CustomSlider
                aria-label="Slider"
                value={typeof sliderValue === "number" ? sliderValue : 0}
                onChange={handleSliderChange}
                aria-labelledby="fee-input-slider"
                defaultValue={500}
                marks={marks}
                min={10}
                max={10000}
                valueLabelDisplay="off"
              />
              <Box display='flex' justifyContent='space-between'>
                <Typography variant='textLg' weight='medium' color={theme.palette.input.heading}>{"$10"}</Typography>
                <Typography variant='textLg' weight='medium' color={theme.palette.input.heading}>{"$10,000"}</Typography>
              </Box>
              <Spacing variant='betweenSectionToInputFields' />
              <Divider />
              <Spacing variant='betweenSectionToInputFields' />
              <Typography variant='text2XL' weight='semibold' color={theme.palette.primary.navy}>{"Total fees:"}</Typography>
              <Spacing variant='betweenHeaderToSubheader' />
             {
                isNotMobile &&  <Box display='flex' justifyContent='space-between'>
                <div className={classes['rb-fees-calculator-fees-box1']}>
                    <div className={classes['rb-fees-calculator-fees-box2']} >
                <Typography variant='textXl' weight='medium' color={theme.palette.input.heading}>{"Remitbee Balance "}</Typography>
                </div>
                <div  className={classes['rb-fees-calculator-fees-box3']}>
                <Typography variant='textXl' weight='medium' color={fees.wallet > 0 ? theme.palette.input.heading : theme.palette.success.regular}>{fees.wallet > 0 ? `$${fees.wallet}` : 'Free'}</Typography>
                </div>
                </div>
                <div className={classes['rb-fees-calculator-fees-box1']}>
                    <div className={classes['rb-fees-calculator-fees-box2']} >
                <Typography variant='textXl' weight='medium' color={theme.palette.input.heading}>{"Interac "}</Typography>
                </div>
                <div  className={classes['rb-fees-calculator-fees-box3']}>
                <Typography variant='textXl' weight='medium' color={fees.interac > 0 ? theme.palette.input.heading : theme.palette.success.regular}>{fees.interac > 0 ? `$${fees.interac}` : 'Free'}</Typography>
                </div>
                </div>
                <div className={classes['rb-fees-calculator-fees-box1']}>
                    <div className={classes['rb-fees-calculator-fees-box2']} >
                <Typography variant='textXl' weight='medium' color={theme.palette.input.heading}>{"Debit card fee: "}</Typography>
                </div>
                <div  className={classes['rb-fees-calculator-fees-box3']}>
                <Typography variant='textXl' weight='medium' color={fees.debit > 0 ? theme.palette.input.heading : theme.palette.success.regular}>{fees.debit > 0 ? `$${fees.debit}` : 'Free'}</Typography>
                </div>
                </div>
              </Box>
             }
              {!isNotMobile && <>
                <Box display='flex' justifyContent='space-between'>
                <Typography variant='textMd' weight='medium' color={theme.palette.input.heading}>{"Remitbee Balance "}</Typography>
                <Typography variant='textMd' weight='medium' color={fees.wallet > 0 ? theme.palette.input.heading : theme.palette.success.regular}>{fees.wallet > 0 ? `$${fees.wallet}` : 'Free'}</Typography>
              </Box>
              <Spacing variant='betweenButtons' />
              <Box display='flex' justifyContent='space-between'>
                <Typography variant='textMd' weight='medium' color={theme.palette.input.heading}>{"Debit"}</Typography>
                <Typography variant='textMd' weight='medium' color={fees.debit > 0 ? theme.palette.input.heading : theme.palette.success.regular}>{fees.debit > 0 ? `$${fees.debit}` : 'Free'}</Typography>
              </Box>
              <Spacing variant='betweenButtons' />
              <Box display='flex' justifyContent='space-between'>
                <Typography variant='textMd' weight='medium' color={theme.palette.input.heading}>{"Interac "}</Typography>
                <Typography variant='textMd' weight='medium' color={fees.interac > 0 ? theme.palette.input.heading : theme.palette.success.regular}>{fees.interac > 0 ? `$${fees.interac}` : 'Free'}</Typography>
              </Box>
              <Spacing variant='betweenSectionToInputFields' />
                <Button className={classes['rb-fees-calculator-button']} id='start-sending' fullWidth variant='filledPrimary' onClick={() => router.push("/signup")}>{"Start sending"}</Button>
              </>}
        </Box>
    );
}

export default FeesCalculator;