import React, { useEffect, useState } from 'react'
import classes from './FeeCalculator.module.scss'
import Box from '../../../../../remitbee/components/box/Box';
import Typography from '../../../../../remitbee/components/typography/Typography';
import theme from '../../../../../remitbee/theme/Theme';
import Flag from '../../../../../remitbee/components/flag/Flag';
import Button from '../../../../../remitbee/components/button/Button';
import { CustomSlider } from '../../../components/FeesCalculator/CustomSlider';
import Divider from '../../../../../remitbee/components/divider/Divider';
import { useMediaQuery } from '@mui/material';
import { useRouter } from 'next/router';
import Spacing from '../../../../../remitbee/styles/spacing/Spacing';

const FeeCalculator = ({
    variant
}) => {
    let isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);
    isMobile = isMobile || variant === 'mini';
    const [payMethods, setPayMethods] = useState([
        {
            method: 'Remitbee Balance',
            fee: 'Free'
        },
        {
            method: 'Interac',
            fee: '$2.99 CAD'
        },
        {
            method: 'Debit Card',
            fee: '$2.99 CAD'
        }
    ]);
    const [sliderValue, setSliderValue] = useState<any>(500);
    const [resultAmount, setResultAmount] = useState<any>(null);
    const router = useRouter();

    const handleSliderChange = (event, newValue) => {
        setSliderValue(newValue);
    };

    const handleInputChange = event => {
        const value = event.target.value.includes(',') ? event.target.value.replace(',', '') : event.target.value
        if (Number(value) > 9000) {
            setResultAmount(value)
            setSliderValue(10)
        }
        else {
            setResultAmount(value)
            setSliderValue(value === "" ? "" : Number(value));
        }
    };

    const calculateFees = () => {
        let amount = Number(sliderValue);
        if (amount !== resultAmount) setResultAmount(`${amount}.00`);

        let newFees = {
            wallet: (amount >= 499.99 ? 0 : 2.99),
            interac: (2.99).toFixed(2),
            debit: amount < 3000 ? (amount <= 100 ? 2.99 : amount <= 300 ? 4.99 : amount <= 500 ? 6.99 : amount <= 1000 ? 8.99 : amount <= 1500 ? 14.99 : amount <= 2000 ? 19.99 : amount <= 2500 ? 24.99 : 29.99).toFixed(2) : '29.99'
        };
        setPayMethods([
            {
                method: 'Remitbee Balance',
                fee: newFees.wallet ? `$${newFees.wallet?.toFixed(2)} CAD` : 'Free'
            },
            {
                method: 'Interac',
                fee: `$${newFees.interac} CAD`
            },
            {
                method: 'Debit Card',
                fee: `$${newFees.debit} CAD`
            }
        ]);
    };

    useEffect(() => {
        calculateFees();
    }, [sliderValue]);

    return (
        <Box className={`${classes['rb-container']} ${variant === 'mini' && classes['rb-container-mini']}`}>
            <Box className={classes['rb-converter-container']}>
                <Typography variant='textXl' weight='medium' color={theme.palette.input.body}>
                    You are sending:
                </Typography>
                <Box display='flex' gap={24}  style={{ width: 'fit-content'}}>
                    <div className={classes['rb-flag-container-1']}>
                        <div className={classes['rb-input']}>
                            <input type='text' value={sliderValue} onChange={(e) => setSliderValue(Number(e.target.value))}></input>
                        </div>
                        <div className={classes['rb-flag']}>
                            <Flag code={'CA'} />
                            <Typography variant='textXl' weight='medium'>CAD</Typography>
                        </div>
                    </div>
                    {!isMobile && <Button className={classes['rb-custom-button']} onClick={() => router.push('/signup')} variant='filledPrimary' id='send'>
                        Start sending
                    </Button>}

                </Box>
            </Box>
            <Box display='flex' flexDirection='column' gap={8}>
                <CustomSlider
                    aria-label="Slider"
                    value={typeof sliderValue === "number" ? sliderValue : 0}
                    onChange={handleSliderChange}
                    aria-labelledby="fee-input-slider"
                    defaultValue={500}
                    min={10}
                    max={9000}
                    valueLabelDisplay="off"
                />
                <Box display='flex' justifyContent='space-between'>
                    <Typography variant='textXl' weight='medium' color={theme.palette.primary.navy}>
                        $10
                    </Typography> 
                    <Typography variant='textXl' weight='medium' color={theme.palette.primary.navy}>
                        $10,000
                    </Typography> 
                </Box>
            </Box>
            <Box>
                    <Divider />
                </Box>
                <Box display='flex' flexDirection='column'>
                    {variant === 'large' && <><Typography variant={isMobile ? 'textLg' : 'text2XL'} weight='semibold' color={theme.palette.primary.navy}>
                        Total Fees:
                    </Typography>
                    <Spacing variant='titleToDivider' /></>}
                    <Box display='flex' gap={isMobile ? 16 : 20} flexDirection={isMobile ? 'column' : 'row'}>
                       {payMethods.map(each => (isMobile ? 
                         <Box display='flex' justifyContent='space-between' alignItems='center'>
                            <Typography variant={isMobile ? 'textMd' : 'textXl'} weight='medium' color={theme.palette.primary.navy}>
                                {each.method}
                            </Typography>
                            <Typography variant='textMd' weight='medium' color={each.fee === 'Free' ? theme.palette.success.dark : ''}>
                                {each.fee}
                            </Typography>
                         </Box>
                       :
                        <div className={classes['rb-card-container']}>
                         <div className={classes['rb-frame-1']}><Typography variant='textLg' weight='semibold' color={theme.palette.primary.navy}>{each.method}</Typography></div>
                         <div className={classes['rb-frame-2']}><Typography variant='textXl' weight='semibold' color={each.fee === 'Free' ? theme.palette.success.dark : ''}>{each.fee}</Typography></div>
                       </div>
                    ))}
                    </Box>
                    {isMobile && <Box mt={8}>
                    <Button fullWidth onClick={() => router.push('/signup')} variant='filledPrimary' id='send'>
                        Start sending
                    </Button>
                    </Box>}
                </Box>
        </Box>
    )
}

export default FeeCalculator