import React, { useRef, useState } from 'react';
import classes from './Tab.module.scss';
import Spacing from '../../styles/spacing/Spacing';
import Box from '../box/Box';
import Typography from '../typography/Typography';
import { useMediaQuery } from '@mui/material';
import theme from '../../theme/Theme';

interface TabProps {
 tabContent: {
  label: string
  content: string | React.ReactNode;
 }[],
 type?: 'normal' | 'radio' | 'button',
 dynamicWidth?: boolean,
 id?: string;
 onChange?: any;
 hideTabHeader?: boolean;
 tabHeaderClassName?: any;
 tabBodyClassName?: any;
 defaultActiveTab?: any;
 hideTableBodySpacing?: boolean;
 tabLabelClassName?: any;
 setIndex?: any;
}

const Tabs: React.FC<TabProps> = ({
  tabContent, 
  type='normal', 
  dynamicWidth = false, 
  id, 
  onChange,
  hideTabHeader = false,
  tabHeaderClassName,
  tabBodyClassName,
  defaultActiveTab,
  hideTableBodySpacing,
  tabLabelClassName,
  setIndex
}) => {
  const [activeTab, setActiveTab] = useState<number>(defaultActiveTab || 0);
  const isMobile =  useMediaQuery(`(max-width: ${theme.breakpoints.lg}px)`)

  const handleTabChange = (index:number) => {
     setActiveTab(index);
     setIndex && setIndex(index);
     onChange && onChange(tabContent[index]);
  }

  const tabRefs = useRef<(HTMLDivElement | null)[]>([]); // Create refs for each tab

    const handleTabClick = (index) => {
        setActiveTab(index);
        setIndex && setIndex(index);
        onChange && onChange(tabContent[index]);

        // Scroll the clicked tab into the center
        tabRefs.current[index]?.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center',
        });
    };

  return <>
  {
   type === 'normal' &&  <div id={id} className={classes['tabs-container']}>
   {!hideTabHeader && <div className={`${classes['tabs']} ${tabHeaderClassName ? tabHeaderClassName : ''}`}>
     {tabContent.map((tab, index) => (
       <div
         key={index}
         className={index === activeTab ? classes['tab-active'] : classes[`tab-normal`]}
         onClick={() => handleTabChange(index)}
         style={{ width: dynamicWidth ? !isMobile ? `calc(100% / ${tabContent.length})` : '100%' : '' }}
       >
         <Typography variant='label1' weight='medium' id={tab?.label?.replace(/\s+/g, '-')?.toLowerCase()} className={tabLabelClassName && tabLabelClassName}>{tab.label}</Typography>
       </div>
     ))}
   </div>}
   {(!hideTabHeader && !hideTableBodySpacing) && <Spacing variant='tabToBody' />}
   <div className={`${classes['tab-content']} ${tabBodyClassName ? tabBodyClassName : ''}`}>{tabContent[activeTab].content}</div>
 </div>
  }
  {
   type === 'radio' && <>
   <Box display='flex' >
   {tabContent.map((tab, index) => (
     <div className={`${index === 0 ? classes['rb-method-container-first']:index === tabContent.length - 1 ? classes['rb-method-container-last'] :classes['rb-method-container-middle'] }
     ${index === activeTab && classes['tab-radio-active']}`} onClick={() => setActiveTab(index)}>
       <div className={classes['rb-input-button']}>
       <input type='radio' checked={index === activeTab} readOnly />
     </div>
     <Typography id={tab?.label?.replace(/\s+/g, '-')?.toLowerCase()} style={{ width: '100%', fontFamily: 'Inter'}} variant='body1' className={index === activeTab && classes['tab-active-for-radio']}>{tab.label}</Typography>
     </div>
   ))}
   </Box>
   <div className={classes['tab-content']}>{tabContent[activeTab].content}</div>
   </>
  }
    {type === 'button' && (
      <div id={id} className={`${classes['tabs-container']}`}>
        <Box
          display="flex"
          className={`
            ${classes['rb-tabs-scroll-horizontal']}
            ${tabHeaderClassName ? tabHeaderClassName : ''}
            `}
        >
          {tabContent.map((tab, index) => (
            <div
              key={index}
              className={`${classes['rb-button-tab-container']} ${activeTab === index && classes['rb-button-tab-container-active']}`}
              onClick={() => handleTabClick(index)}
              ref={(el) => {
                tabRefs.current[index] = el;
              }}
            >
              <Typography variant="textMd" weight="semibold">
                {tab.label}
              </Typography>
            </div>
          ))}
        </Box>
        <Spacing variant="betweenCards" />
        <Spacing variant="betweenCards" />
        <div className={classes['tab-content']}>
          {tabContent[activeTab].content}
        </div>
      </div>
    )}
  </>;
};

export default Tabs; 