import React, { useEffect, useRef, useState } from 'react'
import Box from '../../../../../remitbee/components/box/Box'
import classes from './FullWidthSection.module.scss'
import Typography from '../../../../../remitbee/components/typography/Typography'
import theme from '../../../../../remitbee/theme/Theme'
import Divider from '../../../../../remitbee/components/divider/Divider'

const TRANSFER_LIMITS = [
    {
        level: "Level 1",
        daily_limit: "$1000 CAD",
        monthly_limit: "$3000 CAD",
        timeline: "Instant",
        required_docs: "Canadian ID or passport from your origin country."
    },
    {
        level: "Level 2",
        daily_limit: "$3000 CAD",
        monthly_limit: "$25000 CAD",
        timeline: "24 Hours",
        required_docs: "Proof of Address (Bank statement or Utility bill)."
    },
    {
        level: "Level 3",
        daily_limit: "$9000 CAD",
        monthly_limit: "$50000 CAD",
        timeline: "24 Hours - reach out to customer support for express verification if required.",
        required_docs: "Record a video with your ID on our platform."
    },
    {
        level: "Level 4",
        daily_limit: "$20,000.00 CAD",
        monthly_limit: "$200,000.00 CAD",
        timeline: "24 Hours - reach out to customer support for express verification if required.",
        required_docs: "Complete a questionnaire. Your account must be at least 3 months old to be eligible."
    },
]

const renderNumberSVG = (number) => {
  switch (number) {
    case 1:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
          <g clip-path="url(#clip0_5904_22796)">
            <path d="M20.3496 0.594238V31.6228H14.7135V7.77566H14.456C14.254 8.08874 13.8702 8.42716 13.3045 8.79079C12.7389 9.14427 12.0117 9.44726 11.1228 9.69982C10.2442 9.95231 9.21891 10.0786 8.04724 10.0786V5.53336C9.11791 5.53336 10.0725 5.3465 10.9108 4.97278C11.7491 4.59907 12.4612 4.13444 13.047 3.57892C13.6429 3.0234 14.1025 2.47292 14.4257 1.9275C14.7489 1.38207 14.9257 0.937653 14.956 0.594238H20.3496Z" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_5904_22796">
              <rect width="31.2195" height="32" fill="white" transform="translate(0 0.0600586)" />
            </clipPath>
          </defs>
        </svg>
      )
    case 2:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
          <g clip-path="url(#clip0_5904_22806)">
            <path d="M5.11784 32.123V28.0626L15.89 17.5025C16.9203 16.4622 17.7788 15.5381 18.4656 14.73C19.1524 13.922 19.6676 13.1392 20.011 12.3816C20.3544 11.6241 20.5261 10.8161 20.5261 9.95757C20.5261 8.97782 20.3039 8.13945 19.8594 7.44252C19.415 6.73549 18.804 6.19006 18.0262 5.80625C17.2484 5.42243 16.3647 5.23052 15.3749 5.23052C14.3547 5.23052 13.4608 5.44263 12.6932 5.86685C11.9255 6.28097 11.3296 6.87184 10.9054 7.63948C10.4913 8.40713 10.2842 9.32124 10.2842 10.3818H4.93604C4.93604 8.41213 5.3855 6.70013 6.28445 5.24567C7.18339 3.7912 8.42066 2.665 9.99639 1.86707C11.5821 1.06913 13.4002 0.670166 15.4506 0.670166C17.5313 0.670166 19.3595 1.05903 20.9352 1.83677C22.5108 2.61451 23.733 3.6801 24.6016 5.03356C25.4803 6.38702 25.9197 7.93239 25.9197 9.66965C25.9197 10.8312 25.6975 11.9726 25.2531 13.0937C24.8086 14.2149 24.0259 15.4572 22.9047 16.8208C21.7937 18.1844 20.2332 19.8357 18.2232 21.7751L12.875 27.2142V27.4263H26.3894V32.123H5.11784Z" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_5904_22806">
              <rect width="31.2195" height="32" fill="white" transform="translate(0 0.560059)" />
            </clipPath>
          </defs>
        </svg>
      )
    case 3:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
          <g clip-path="url(#clip0_5904_22816)">
            <path d="M15.6084 32.5472C13.4267 32.5472 11.4874 32.1735 9.79056 31.4261C8.10377 30.6786 6.77049 29.6383 5.79075 28.305C4.811 26.9717 4.29083 25.4314 4.23022 23.684H9.92691C9.97741 24.5224 10.2552 25.2547 10.7602 25.8809C11.2652 26.497 11.9369 26.9768 12.7752 27.3202C13.6135 27.6636 14.5529 27.8353 15.5933 27.8353C16.7043 27.8353 17.6891 27.6434 18.5476 27.2596C19.4062 26.8657 20.0779 26.3203 20.5626 25.6234C21.0475 24.9264 21.2848 24.1234 21.2747 23.2144C21.2848 22.2751 21.0425 21.4468 20.5475 20.7296C20.0526 20.0125 19.3355 19.452 18.3961 19.0479C17.4669 18.6439 16.3457 18.4419 15.0326 18.4419H12.2904V14.1088H15.0326C16.1134 14.1088 17.0578 13.922 17.8658 13.5482C18.684 13.1745 19.3254 12.6493 19.7899 11.9726C20.2546 11.2857 20.4818 10.4928 20.4718 9.59394C20.4818 8.71519 20.2849 7.95259 19.8809 7.30617C19.4869 6.64964 18.9264 6.13956 18.1991 5.77594C17.482 5.41233 16.6386 5.23052 15.669 5.23052C14.7196 5.23052 13.8408 5.40223 13.0328 5.74565C12.2247 6.08906 11.5733 6.57893 11.0784 7.21526C10.5834 7.84149 10.3208 8.58891 10.2905 9.45759H4.88171C4.9221 7.72028 5.42208 6.19511 6.38162 4.88206C7.35126 3.5589 8.6441 2.52865 10.2602 1.79132C11.8763 1.04388 13.6893 0.670166 15.6993 0.670166C17.7699 0.670166 19.5677 1.05903 21.093 1.83677C22.6282 2.6044 23.815 3.6397 24.6533 4.94266C25.4917 6.24562 25.9108 7.68493 25.9108 9.2606C25.921 11.008 25.4059 12.4725 24.3655 13.6543C23.3352 14.8361 21.9818 15.6088 20.3051 15.9723V16.2147C22.4868 16.5178 24.1584 17.3258 25.32 18.6389C26.4916 19.9418 27.0724 21.563 27.0623 23.5022C27.0623 25.2395 26.5674 26.795 25.5776 28.1686C24.5978 29.5322 23.2443 30.6029 21.5172 31.3806C19.8001 32.1583 17.8305 32.5472 15.6084 32.5472Z" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_5904_22816">
              <rect width="31.2195" height="32" fill="white" transform="translate(0 0.560059)" />
            </clipPath>
          </defs>
        </svg>
      )
    case 4:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none">
          <g clip-path="url(#clip0_5904_22826)">
            <path d="M3.20886 26.3656V21.8961L16.3748 1.09424H20.1019V7.45752H17.8292L8.96611 21.5022V21.7446H27.3439V26.3656H3.20886ZM18.0111 32.1228V25.002L18.0717 23.0021V1.09424H23.3744V32.1228H18.0111Z" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_5904_22826">
              <rect width="31.2195" height="32" fill="white" transform="translate(0 0.560059)" />
            </clipPath>
          </defs>
        </svg>
      )
  }
}

const TransferLimitsCard = ({ isMobile }) => {

    const [maxHeight, setMaxHeight] = useState(0);
    const cardRefs = useRef<any>([]);

useEffect(() => {
    const heights = cardRefs.current.map(card => card.getBoundingClientRect().height);
    const maxCardHeight = Math.max(...heights);
    setMaxHeight(maxCardHeight);
}, [cardRefs?.current?.length]);

  return (
    <Box className={classes['rb-transfer-limits-container']}>
       {TRANSFER_LIMITS.map((e,i) => (
          <Box key={i} componentRef={el => (cardRefs.current[i] = el)} className={classes['rb-transfer-limit-card']} style={!isMobile ? { minHeight: '390px',  maxHeight} : {}}>
            <div className={classes['rb-limit-header']}>
                <div className={classes['rb-limit-text']}>
                  {renderNumberSVG(i + 1)}
                </div>
                <Typography variant='textXl' weight='bold' color={theme.palette.primary.navy}>{e.level}</Typography>
                <div className={classes['rb-divider']}><Divider /></div>
            </div>
            <div className={classes['rb-limit-footer']}>
              <Box>
                <Typography align='center' variant='textMd' weight='semibold' color={theme.palette.input.body}>{`Daily limit: ${e.daily_limit}`}</Typography>
                <Typography align='center' variant='textMd' weight='semibold' color={theme.palette.input.body}>{`Monthly limit: ${e.monthly_limit}`}</Typography>
              </Box>
              <Typography align='center' variant='textSm'>{`Required Documents: ${e.required_docs}`}</Typography>
              <Typography align='center' variant='textSm'>{`Time: ${e.timeline}`}</Typography>
            </div>
          </Box>
        ))}
    </Box>
  )
}

export default TransferLimitsCard