import React, { useState } from 'react'
import Box from '../../../../../remitbee/components/box/Box';
import classes from './FullWidthSection.module.scss'
import Tabs from '../../../../../remitbee/components/tab/Tab';
import Typography from '../../../../../remitbee/components/typography/Typography';
import theme from '../../../../../remitbee/theme/Theme';
import Spacing from '../../../../../remitbee/styles/spacing/Spacing';
import { useMediaQuery } from '@mui/material';
import Input from '../../../../../remitbee/components/input/Input';
import DropDown from '../../../../../remitbee/components/dropDown/DropDown';
import DropDownItem from '../../../../../remitbee/components/dropDown/DropDownItem';
import Table from './Table';
import NumberFormat from 'react-number-format';
import { formatNumber } from '../../../../../shared/math';


const GSTCalculator = ({
    provinces
}) => {
  const [value, setValue] = useState('after_taxes');
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);

  const AmountCalc = (input, tax) => {
    let value = parseInt(input);
    let taxInput = parseInt(tax);
    return ((taxInput / 100) * value).toFixed(2);
  };

  const Calculator = ({ type }) => {
    const [input, setInput] = useState('');
    const [currentProvince, setCurrentProvince] = useState<any>('');

    let GST:any = input && currentProvince ? AmountCalc(input, currentProvince?.GST) : 0;
    let PST:any = input && currentProvince ?  AmountCalc(input, currentProvince.PST) : 0;
    let HST = (parseFloat(GST) + parseFloat(PST)).toFixed(2);
    let TotalAmount = input && currentProvince ? type === 'after_taxes' ? (parseFloat(input) + parseFloat(HST)).toFixed(2) : (parseFloat(input) - parseFloat(HST)).toFixed(2) : 0;
    
    const handleProvinceChange = (event) => {
      let selectedProvince = JSON.parse(event.currentTarget.getAttribute('data-item'));
      if(!selectedProvince) return;
      setCurrentProvince(selectedProvince);
    };
  

    const handleChange = (values) => {
      const { floatValue } = values;
        setInput(floatValue ? floatValue.toFixed(2) : '');
    };

    const renderProvince = (province) => {
      if (!province) return null;
      return (
        <DropDownItem
          key={province?.province}
          onClick={handleProvinceChange}
          data={province}
          value={province?.province}
          id={`purpose-${province?.province}`}
        >
          <Typography variant="label" color={theme.palette.primary.navy}>{province?.province}</Typography>
        </DropDownItem>
      )
    }

    return (
        <Box mt={8}>
          <Typography variant={'textXl'} weight='semibold' color={theme.palette.primary.navy}>
            {type === 'after_taxes' ? "Amount after taxes" : "Amount before taxes"}
          </Typography>

          <Spacing variant='titleToDivider' />

          <Box display='flex' flexDirection={isMobile ? 'column' : 'row'} gap={16}>
            <Box display='flex' flexDirection='column' gap={4}>
            <Typography variant='body2' color={theme.palette.input.body}>{'Amount'}</Typography>
            <Box className={classes['rb-input']}>
            <NumberFormat
              value={input || ''}
              displayType="input"
              aria-label="Amount"
              placeholder="Enter amount"
              onValueChange={handleChange}
              thousandSeparator={true}
              decimalScale={2}
              isNumericString={true}
            />
            </Box>
            </Box>
            <Box>
            <DropDown
              label='Province'
              list={provinces.map((province) => renderProvince(province))}
              selectedItem={currentProvince && <Typography variant="label" color={theme.palette.primary.navy}>{currentProvince.province}</Typography>}
              placeholder='Select Province'
            /></Box>
          </Box>
          <Spacing variant='betweenSectionToInputFields' />
          <Typography variant='textXl' weight='semibold' color={theme.palette.primary.navy} >
            GST/HST Calculator
          </Typography>
          <Spacing variant='titleToDivider' />
          <Box className={classes['rb-calc-table-container']}>
            <Box className={classes['rb-calc-cell']}>
              <Typography variant='textMd' >
              PST {`(${currentProvince ? currentProvince.PST : 0}%)`}
              </Typography>
              <Typography variant='textMd' weight='medium' >
                ${formatNumber(PST, { useGrouping: true })}
              </Typography>
            </Box>
            <Box className={classes['rb-calc-cell']}>
              <Typography variant='textMd' >
              GST {`(${currentProvince ? currentProvince.GST : 0}%)`}
              </Typography>
              <Typography variant='textMd' weight='medium' >
                ${formatNumber(GST, { useGrouping: true })}
              </Typography>
            </Box>
            <Box className={classes['rb-calc-cell']}>
              <Typography variant='textMd' >
              HST {`(${currentProvince ? +currentProvince.GST + +currentProvince.PST : 0}%)`}
              </Typography>
              <Typography variant='textMd' weight='medium' >
                ${formatNumber(HST, { useGrouping: true })}
              </Typography>
            </Box>
            <Box className={classes['rb-calc-cell']}>
              <Typography variant='textLg' weight='semibold'>
              {type === 'after_taxes' ? "Total after taxes" : "Total before taxes"}
              </Typography>
              <Typography variant='textMd' weight='medium' color={theme.palette.primary.navy}>
              {`$${formatNumber(TotalAmount, { useGrouping: true })}`}
              </Typography>
            </Box>
          </Box>
        </Box>
    )

  }

  return (
    <>
      <Box className={classes['rb-calc-container']}>
         <Tabs
            tabHeaderClassName={classes['rb-tab-header']}
            hideTableBodySpacing
            tabContent={[
              {
                label: 'After Taxes',
                content: <Calculator type='after_taxes' />
              },
              {
                label: 'Before Taxes',
                content: <Calculator type='before_taxes' />
              }
            ]}
            dynamicWidth={true}
            />
      </Box>
    </>
  )
}

export default GSTCalculator