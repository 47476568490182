import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import arraySupport from 'dayjs/plugin/arraySupport';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import advancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(arraySupport);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(advancedFormat);

// Last parameter is to keep the value that came on start_time. If is false, the value will be updated accordingly with the timezone GMT.
// moment(start_time, 'hh:mma').tz('America/Toronto', true)

export var currentTimeIsBetweenTwoTimes = function currentTimeIsBetweenTwoTimes(start_time, end_time) {
  var startTime = dayjs(start_time, 'hh:mma').tz('America/Toronto', true);
  var endTime = dayjs(end_time, 'hh:mma').tz('America/Toronto', true);
  var currentTime = dayjs().tz('America/Toronto');
  startTime = dayjs([currentTime.year(), currentTime.month(), currentTime.date(), startTime.hour(), startTime.minute(), 0]).tz('America/Toronto', true);
  endTime = dayjs([currentTime.year(), currentTime.month(), currentTime.date(), endTime.hour(), endTime.minute(), 0]).tz('America/Toronto', true);
  var isTimeBetween = false;
  if (startTime.isBefore(endTime)) isTimeBetween = currentTime.isBetween(startTime, endTime, null, '[]');else {
    var beforeMidnight = dayjs('11:59 PM', 'hh:mma').tz('America/Toronto', true);
    var afterMidnight = dayjs('12:00 AM', 'hh:mma').tz('America/Toronto', true);
    beforeMidnight = dayjs([currentTime.year(), currentTime.month(), currentTime.date(), beforeMidnight.hour(), beforeMidnight.minute(), 0]).tz('America/Toronto', true);
    afterMidnight = dayjs([currentTime.year(), currentTime.month(), currentTime.date(), afterMidnight.hour(), afterMidnight.minute(), 0]).tz('America/Toronto', true);
    isTimeBetween = currentTime.isBetween(startTime, beforeMidnight, null, '[]') || currentTime.isBetween(afterMidnight, endTime, null, '[]');
  }
  return isTimeBetween;
};
export var maximumCustomerAge = function maximumCustomerAge() {
  return dayjs().subtract(14, 'year').format('YYYY-MM-DD');
};

/**
 * This methods receives the America/Toronto date from the backend and parses to the actually client's timezone.
 * @param {date} date America/Toronto date
 */
export var dateByCusTz = function dateByCusTz(date) {
  var dateConverted = null;
  if (date) {
    var dateTorontoTz = dayjs.tz(date, ['MMM DD, YYYY h:mm a', 'MMM DD, YY', 'YYYY-MM-DD hh:mm:ss'], 'America/Toronto');
    var cusTz = dateTorontoTz.clone().tz(dayjs.tz.guess());
    if (cusTz.isValid()) dateConverted = cusTz;
  }
  return dateConverted;
};

/**
 * @function formatCalendarDate
 * @description Format date in MMM DD, YYYY h:mm pattern.
 * @param {string} date
 */
export var formatCalendarDate = function formatCalendarDate(date) {
  if (!date) return;
  var dateToBeReturned = '';
  var dateConverted = dayjs.tz(date, dayjs.tz.guess());
  if (dateConverted.isValid()) {
    dateToBeReturned = dateConverted.format('MMMM D, YYYY  h:mm:ss a');
  }
  return dateToBeReturned;
};

/**
 * @function formatCalendarDateOnly
 * @description Format date in MMM DD, YY pattern.
 * @param {string} date
 */
export var formatCalendarDateOnly = function formatCalendarDateOnly(date) {
  var dateToBeReturned = '';
  if (date) {
    var dateConverted = dateByCusTz(date);
    if (dateConverted && dateConverted.isValid()) dateToBeReturned = dateConverted.format('MMM DD, YYYY');
  }
  return dateToBeReturned;
};
export var getOrdinal = function getOrdinal(day) {
  if (!day || day < 1 || day > 31 || typeof +day !== 'number') return;
  if (day >= 11 && day <= 13) {
    return 'th';
  }
  var lastDigit = day % 10;
  switch (lastDigit) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    case 31:
      return 'st';
    default:
      return 'th';
  }
};
export function formatHolidayDateOrdinal(dates) {
  // Skip MomentJS ISO format warning during testing
  // moment.suppressDeprecationWarnings = true;
  var formattedDates = dates.filter(function (date) {
    return dayjs(date).isValid();
  }).map(function (date) {
    return dayjs(date);
  });
  var result = '';
  for (var i = 0; i < formattedDates.length; i++) {
    var currentDate = formattedDates[i];
    var currentMonth = currentDate.format('MMMM');
    var currentDay = currentDate.format('D');
    var currentOrdinal = getOrdinal(+currentDay);
    if (i === 0) {
      result = "".concat(currentMonth, " ").concat(currentDay).concat(currentOrdinal);
    } else {
      var previousDate = formattedDates[i - 1];
      var previousMonth = previousDate.format('MMMM');
      if (currentMonth === previousMonth) {
        var connector = i === formattedDates.length - 1 ? ' & ' : ', ';
        result += "".concat(connector).concat(currentDay).concat(currentOrdinal);
      } else {
        result += " & ".concat(currentMonth, " ").concat(currentDay).concat(currentOrdinal);
      }
    }
  }
  return result;
}
export var extractPredictedDate = function extractPredictedDate(_ref) {
  var paymentTypesDetails = _ref.paymentTypesDetails,
    type = _ref.type,
    serviceType = _ref.serviceType,
    paymentType = _ref.paymentType,
    t = _ref.t;
  if (!type || !serviceType || !paymentType || !t) return {};
  if (!paymentTypesDetails || (paymentTypesDetails === null || paymentTypesDetails === void 0 ? void 0 : paymentTypesDetails.length) < 1) return {};
  var walletFundingTimes = paymentTypesDetails.find(function (pt) {
    return pt.type === paymentType || pt.payment_type === paymentType;
  });
  /**
   * NOTE
   * walletFundingTimes?.timeline - this will handle ExchangeInput on landing page
   * walletFundingTimes?.funding_times - this will handle on payment options
   */
  var fundingTimes = walletFundingTimes !== null && walletFundingTimes !== void 0 && walletFundingTimes.timeline ? {
    timeline: walletFundingTimes === null || walletFundingTimes === void 0 ? void 0 : walletFundingTimes.timeline
  } : ((walletFundingTimes === null || walletFundingTimes === void 0 ? void 0 : walletFundingTimes.funding_times) || []).find(function (k) {
    return k.service_type === serviceType;
  });
  var _ref2 = fundingTimes || {},
    timeline = _ref2.timeline;
  if (timeline) {
    var timelineValue = timeline["".concat(type, "_timeline")]['predicted_date'];
    var holidayDatesValue = timeline["".concat(type, "_timeline")]['holiday_dates'];
    return timelineValue ? {
      translatedDate: t("common:".concat(serviceType, ".").concat(type, "_timeline.predicted_date"), {
        date: timelineValue
      }),
      formattedDate: timelineValue,
      holidayDates: holidayDatesValue ? formatHolidayDateOrdinal(holidayDatesValue) : null
    } : {};
  }
  return {};
};
export var extractPredictedDay = function extractPredictedDay(_ref3) {
  var paymentTypesDetails = _ref3.paymentTypesDetails,
    type = _ref3.type,
    serviceType = _ref3.serviceType,
    paymentType = _ref3.paymentType;
  if (!type || !serviceType || !paymentType) return;
  if (!paymentTypesDetails || (paymentTypesDetails === null || paymentTypesDetails === void 0 ? void 0 : paymentTypesDetails.length) < 1) return;
  var walletFundingTimes = paymentTypesDetails.find(function (pt) {
    return pt.type === paymentType || pt.payment_type === paymentType;
  });
  var fundingTimes = ((walletFundingTimes === null || walletFundingTimes === void 0 ? void 0 : walletFundingTimes.funding_times) || []).find(function (k) {
    return k.service_type === serviceType;
  });
  var _ref4 = fundingTimes || {},
    timeline = _ref4.timeline;
  if (timeline) {
    var timelineValue = timeline["".concat(type, "_timeline")]['predicted_date_time'] || timeline["".concat(type, "_timeline")]['predicted_date'];
    var formattedDate = dayjs(timelineValue).format('MMM D, YYYY');
    return formattedDate;
  }
  return;
};
export var toMonthDayByDateTime = function toMonthDayByDateTime(datetime) {
  if (!datetime) return;
  var dateConverted = dateByCusTz(datetime);
  if (dateConverted && dateConverted.isValid()) return dateConverted.format('MMMM Do');
};
export var extractPredictedDayByDateTime = function extractPredictedDayByDateTime(_ref5) {
  var paymentTypesDetails = _ref5.paymentTypesDetails,
    type = _ref5.type,
    serviceType = _ref5.serviceType,
    paymentType = _ref5.paymentType;
  if (!type || !serviceType || !paymentType) return;
  if (!paymentTypesDetails || (paymentTypesDetails === null || paymentTypesDetails === void 0 ? void 0 : paymentTypesDetails.length) < 1) return;
  var walletFundingTimes = paymentTypesDetails.find(function (pt) {
    return pt.type === paymentType || pt.payment_type === paymentType;
  });
  var fundingTimes = ((walletFundingTimes === null || walletFundingTimes === void 0 ? void 0 : walletFundingTimes.funding_times) || []).find(function (k) {
    return k.service_type === serviceType;
  });
  var _ref6 = fundingTimes || {},
    timeline = _ref6.timeline;
  if (!timeline) return;
  var timelineValue = timeline["".concat(type, "_timeline")]['predicted_date_time'];
  return toMonthDayByDateTime(timelineValue);
};