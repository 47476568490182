import React, { useState, useEffect } from 'react';
import { getCompetitionRanking } from '../../../redux/rewards/actions';
import classes from './ReferralCompetitionSection.module.scss';
import theme from '../../theme/Theme';
import Typography from '../../components/typography/Typography';
import Box from '../../components/box/Box';
import Button from '../../components/button/Button';
import ArrowLeft from '../../icons/ChevronLeft';
import ArrowRight from '../../icons/ArrowRight';
import Spacing from '../../styles/spacing/Spacing';
import { snackbar } from '../../components/snackbar/SnackBar';

const fields = [
    {
        name: "Position",
        attribute: "position",
        align: "center",
        width: '25%'
    }, {
        name: "Name",
        attribute: "cus_firstname",
        width: '50%'
    }, {
        name: "Referrals",
        attribute: "total_rewards",
        align: "center",
        width: '25%'
    }
];

interface ReferralCompetitionSectionProps {
    competition: any;
    isMobile: boolean;
    noDataLabel?: string;
    overrideLabel?: boolean;
    competitionDate: string;
    paddingTop?: number;
    isPublic?: boolean;
}

const ReferralCompetitionSection: React.FC<ReferralCompetitionSectionProps> = ({
    isMobile,
    competition,
    overrideLabel,
    competitionDate,
    paddingTop = 0,
    isPublic = false
}) => {
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [leaderboardData, setLeaderboardData] = useState(null);
    const [page, setPage] = useState(0);
    const [dataPaginated, setDataPaginated] = useState([]);

    useEffect(() => {
        if (competition && !overrideLabel) loadRewardsCompetition();
    }, []);

    useEffect(() => {
        setDataPaginated(leaderboardData && leaderboardData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
    }, [page, rowsPerPage, leaderboardData]);

    useEffect(() => {
        setPage(0)
    }, [rowsPerPage]);

    const loadRewardsCompetition = async () => {
        const { success, data } = await getCompetitionRanking({ start_date: competitionDate });
        if (success && data) setLeaderboardData(data.getReferralCompetition && data.getReferralCompetition.length ? data.getReferralCompetition : null);
        else snackbar.error('Error while loading referral Leaderboard.', 5000);
    }

    const handlePageChange = (event, page) => {
        setPage(page);
    }

    if (!dataPaginated?.length) return null;

    return (
        <Box pt={paddingTop || (isPublic && (isMobile ? 15 : 20))}>
            <div className={`${isMobile && classes['rb-competition-table-box']} ${isPublic && classes['rb-competition-table-box-public']}`}>
                <table className={classes['rb-competition-table']}>
                    {!isMobile && <tr>
                        {fields && fields.length && fields.map((field, index) => (
                            <th
                                style={{ width: field.width }}
                                key={index}
                                className={classes[isPublic ? 'rb-competition-table-head-public' : 'rb-competition-table-head']}
                                align={field.align as "center" | "left" | "right" | "justify" | "char"}
                            >
                                <Typography
                                    variant={isPublic ? 'textXl' : 'label2'}
                                    weight='semibold'
                                    color={theme.palette.primary.navy}
                                    align={field.align as "center" | "left" | "right" | "justify" | "char"}
                                >
                                    {field.name}
                                </Typography>
                            </th>
                        ))}
                    </tr>}
                    <tbody>
                        {dataPaginated && dataPaginated.length && dataPaginated.map((row, rowIndex) => (
                            <tr
                                key={rowIndex}
                                className={isMobile ?
                                    (rowIndex % 2 !== 0 && classes['rb-competition-table-row-odd']) :
                                    (isPublic && rowIndex % 2 === 0 && classes['rb-competition-table-row-odd'])}
                            >
                                {fields && fields.length && fields.map((field, cellIndex) => (
                                    <td
                                        key={cellIndex}
                                        className={classes['rb-competition-table-body']}
                                        align={field.align as "center" | "left" | "right" | "justify" | "char"}
                                    >

                                        <Typography
                                            variant='label1'
                                            color={theme.palette.primary.navy}
                                            align={field.align as "center" | "left" | "right" | "justify" | "char"}
                                        >
                                            {field.attribute === 'cus_firstname' ? row[field.attribute] || 'Customer' : row[field.attribute]}
                                        </Typography>
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>

                <Spacing variant='betweenSectionsSmall' />

                <Box display='flex' justifyContent='center'>
                    <Button
                        id="prev-page"
                        variant='text'
                        disabled={page === 0}
                        onClick={(e) => handlePageChange(e, page - 1)}
                        className={classes['rb-competition-pagination-button']}
                    >
                        <ArrowLeft width={24} height={24} color={
                            page === 0 ?
                                theme.palette.input.body :
                                theme.palette.primary.blue} />
                    </Button>
                    <Button
                        id="next-page"
                        variant='text'
                        disabled={page === Math.ceil(leaderboardData?.length / rowsPerPage) - 1}
                        onClick={(e) => handlePageChange(e, page + 1)}
                        className={classes['rb-competition-pagination-button']}
                    >
                        <ArrowRight width={24} height={24} color={
                            page === Math.ceil(leaderboardData?.length / rowsPerPage) - 1 ?
                                theme.palette.input.body :
                                theme.palette.primary.blue
                        } />
                    </Button>
                </Box>
            </div>
        </Box>
    );
}
export default ReferralCompetitionSection;
