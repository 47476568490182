import Container from '../../components/container/Container';
import Divider from '@mui/material/Divider';
import Typography from '../../components/typography/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { useTranslation } from 'next-i18next';
import theme from '../../theme/Theme';
import classes from './FooterSection.module.scss';
import Box from '../../components/box/Box';
import Spacing from '../../styles/spacing/Spacing';
import Image from '../../components/image/Image';
import Chip from '../../components/chip/Chip';
import AppBadgesSection from '../AppBadgesSection/AppBadgesSection';

interface FooterSectionProps {
    isMobileServer?: boolean;
}

const FooterSection: React.FC<FooterSectionProps> = ({
    isMobileServer
}) => {
    const { t } = useTranslation('landing');
    const isMobile = isMobileServer || useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);

    const COLUMN_1 = {
        title: 'Company',
        links: [
            {
                label: "About RemitBee",
                url: "/about"
            },
            {
                label: "Security",
                url: "/security"
            },
            {
                label: "Blog",
                url: "/blog"
            },
            {
                label: "Business perks",
                url: "/business-perks"
            },
            {
                label: "Careers",
                url: "/careers"
            }
        ]
    }

    const COLUMN_2 = {
        title: 'Products & tools',
        links: [
            {
                label: "RemitBee Balance",
                url: "/remitbee-balance"
            },
            {
                label: "Currency exchange",
                url: "/currency-exchange"
            },
            {
                label: "Business accounts",
                url: "/business"
            },
            {
                label: "Mobile top-up",
                url: "/mobile-topup"
            },
            // {
            //     label: "Gift cards",
            //     url: "/international-gift-cards"
            // },
            {
                label: "International bill payments",
                url: "/international-bill-pay"
            },
            {
                label: <span>{'RemitBee eSIM'} <Chip variant={'footer'} value={'New'} /></span>,
                url: '/esim'
            },
            // {
            //     label: "RemitBee eSIM",
            //     url: "/careers"
            // },
            {
                label: "IBAN calculator",
                url: "/iban-calculator"
            }
        ]
    }

    const COLUMN_3 = {
        title: 'Transfer money',
        links: [
            {
                label: "Send money to India",
                url: "/send-money-to-india"
            },
            {
                label: "Send money to Sri Lanka",
                url: "/send-money-to-sri-lanka"
            },
            {
                label: "Send money to Philippines",
                url: "/send-money-to-philippines"
            },
            {
                label: "Send money to Bangladesh",
                url: "/send-money-to-bangladesh"
            },
            {
                label: "Send money to Ukraine",
                url: "/send-money-to-ukraine"
            },
            {
                label: "Send money to Mexico",
                url: "/send-money-to-mexico"
            },
            {
                label: "Send money to Nigeria",
                url: "/send-money-to-nigeria"
            }
        ]
    }

    const COLUMN_4 = {
        title: 'Help and support',
        links: [
            {
                label: "Help Centre",
                url: "/help"
            },
            {
                label: "How to pay",
                url: "/how-to-pay"
            },
            {
                label: "Our fees",
                url: "/our-fees"
            },
            {
                label: "Contact us",
                url: "/help#help-contact-us"
            }
        ]
    }

    const COLUMN_5 = {
        title: 'Legal',
        links: [
            {
                label: "Privacy policy",
                url: "/privacy"
            },
            {
                label: "Terms & conditions",
                url: "/terms-and-conditions"
            },
            {
                label: "Transaction Guarantee Policy",
                url: "/transaction-guarantee-policy"
            }
        ]
    }

    const SOCIAL_MEDIA = [
        {
            name: 'Facebook',
            url: 'https://www.facebook.com/Remitbee/',
            icon: '/img/landing/new_facebook_icon.svg'
        },
        {
            name: 'X',
            url: 'https://x.com/remitbee?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
            icon: '/img/landing/new_x_icon.svg'
        },
        {
            name: 'Instagram',
            url: 'https://www.instagram.com/remitbee/?igshid=so78ydqsry22',
            icon: '/img/landing/new_instagram_icon.svg'
        },
        {
            name: 'LinkedIn',
            url: 'https://ca.linkedin.com/company/remitbee',
            icon: '/img/landing/new_linkedin_icon.svg'
        },
        {
            name: 'TikTok',
            url: 'https://www.tiktok.com/@remitbee',
            icon: '/img/landing/new_tiktok_icon.svg'
        }
    ]

    const renderColumn = (column: any) => {
        return (
            <div>
                <Typography
                    variant='textSm'
                    weight='semibold'
                    color={theme.palette.primary.navy}
                >
                    {column.title}
                </Typography>
                <Spacing variant='betweenHeaderToSubheader' />

                <div className={classes['rb-footer-links']}>
                    {column.links.map((link: any, index: React.Key) =>
                        <Typography
                            variant='textSm'
                            weight='medium'
                            key={index}
                            link
                        >
                            <a href={link.url}>{link.label}</a>
                        </Typography>
                    )}
                </div>
            </div>
        )
    }

    const badges = () => {
        return (
            <Box display='flex' gap={16}>
                <a aria-label='Download at App Store' target="_blanc" href="https://apps.apple.com/ca/app/remitbee-money-transfer/id983795500" className={classes['rb-footer-download-badge']}>
                    <Image
                        alt='App Store'
                        url="/design-system/footer/download-app-store.svg"
                        width={187}
                        height={56}
                    />
                </a>
                <a aria-label='Download at Play Store' target="_blanc" href="https://play.google.com/store/apps/details?id=com.remitbee.app.app&hl=en_CA" className={classes['rb-footer-download-badge']}>
                    <Image
                        alt='Play store'
                        url="/design-system/footer/download-play-store.svg"
                        width={187}
                        height={56}
                    />
                </a>
            </Box>
        )
    }

    return (
        <div className={classes['rb-footer-container-root']}>
            <Divider
                sx={{
                    opacity: "0.6"
                }} />
            <Container type='large'>
                <div className={classes['rb-footer-container']}>
                    <div className={classes['rb-footer-container-links']}>
                        {renderColumn(COLUMN_1)}
                        {renderColumn(COLUMN_2)}
                        {renderColumn(COLUMN_3)}
                        {renderColumn(COLUMN_4)}
                        {renderColumn(COLUMN_5)}
                    </div>

                    <div className={classes['rb-footer-download']}>

                        {!isMobile ? (
                            <Box display='flex' alignItems='center'>
                                <div className={classes['rb-footer-qr']}>
                                    <Image
                                        url="/design-system/footer/qr-code.svg"
                                        width={80}
                                        height={80}
                                        alt='QR code'
                                    />
                                </div>

                                <div style={{ flexGrow: 1 }}>
                                    <Typography
                                        variant='textSm'
                                        weight='medium'
                                    >
                                        {'Scan to get our app'}
                                    </Typography>
                                </div>

                                <div>
                                    <AppBadgesSection />
                                </div>
                            </Box>
                        ) : <AppBadgesSection />}
                    </div>

                    <Divider
                        sx={{
                            opacity: "0.6"
                        }} />

                    <div className={classes['rb-footer-copyright']}>
                        <div style={{ flexGrow: 1 }}>
                            <Typography
                                variant='textSm'
                                weight='medium'
                            >
                                {`© ${new Date().getFullYear()} RemitBee Inc. All Rights Reserved.`}
                            </Typography>
                        </div>

                        <div className={classes['rb-footer-social']}>
                            {SOCIAL_MEDIA.map((social, index) =>
                            <div className={classes['rb-footer-social-img']} key={index}>
                                <a key={index} target='_blank' href={social.url}>
                                    <Image
                                        url={social.icon}
                                        alt={social.name || 'social media'}
                                        width={24}
                                        height={24}
                                    />
                                </a>
                            </div>
                            )}
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default FooterSection;
