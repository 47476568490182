import React from 'react';
import Box from '../../components/box/Box';
import Image from '../../components/image/Image';
import classes from './AppBadgesSection.module.scss';

interface AppBadgesSectionProps {
    gap?: number;
}

const AppBadgesSection: React.FC<AppBadgesSectionProps> = ({
    gap = 16,
}) => {
    return (
        <Box display='flex' gap={gap}>
            <a aria-label='Download at App Store' target="_blanc" href="https://apps.apple.com/ca/app/remitbee-money-transfer/id983795500" className={classes['rb-app-badge']}>
                <Image
                    alt='App Store'
                    url="/design-system/footer/download-app-store.svg"
                    width={187}
                    height={56}
                />
            </a>
            <a aria-label='Download at Play Store' target="_blanc" href="https://play.google.com/store/apps/details?id=com.remitbee.app.app&hl=en_CA" className={classes['rb-app-badge']}>
                <Image
                    alt='Play store'
                    url="/design-system/footer/download-play-store.svg"
                    width={187}
                    height={56}
                />
            </a>
        </Box>
    );
};

export default AppBadgesSection;
